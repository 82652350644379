import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDesc: description
        siteUrl
        siteTitle: title
      }
    }
  }
`

const SEO = ({title, description}) => {
  const{site} = useStaticQuery(query);
  const{
    siteDesc,
    siteTitle,
    siteUrl
  } = site.siteMetadata
  return <Helmet htmlAttributes={{lang:"en"}} title={`${title} | ${siteTitle}`}>
    <meta name="description" content={description || siteDesc}></meta>
  </Helmet>
}

export default SEO
